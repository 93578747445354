<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @cancel="handleModalCancel"
        width="60%"
        :destroy-on-close="true"
        :footer="null">
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- bulan -->
            <AFormItem
                label="Pilih Bulan"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="bulan"
                has-feedback>
                <AMonthPicker
                    v-model:value="form.bulan"
                    :allow-clear="false"
                    placeholder="Pilih Bulan"
                    :disabled-date="disabledDate"
                    format="MMMM YYYY"
                    @change="fetchTargetDay"
                    :disabled="form.readOnly"
                    style="width:100%;"/>
            </AFormItem>

            <!-- target visit -->
            <AFormItem
                label="Target Visit"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="bulan"
                has-feedback>
                <AInputNumber
                    placeholder="number"
                    v-model:value="form.target_visit"
                    style="width: 100%"/>
            </AFormItem>

            <!-- target noo -->
            <AFormItem
                label="Target NOO"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="bulan"
                has-feedback>
                <AInputNumber
                    placeholder="number"
                    v-model:value="form.target_noo"
                    style="width: 100%"/>
            </AFormItem>

            <ARow
                v-if="!form.readOnly"
                class="form-row"
                type="flex"
                justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="state.errors">
            <ASpace>
                <AAlert
                    v-for="(item, index) in state.errors"
                    type="error"
                    :key="index"
                    :message="item"
                    banner
                    closable
                    @close="state.errors = []" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    computed,
} from 'vue'
import Form from 'vform'
import { Modal, message } from 'ant-design-vue'
import moment from 'moment'
import localStorage from 'store'
import {
    ROLE_ADMIN_BK,
    hasRoles,
} from '@/helpers'

export default defineComponent({
    components: {
        // 
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const user = computed(() => localStorage.get('profile'))

        const form = reactive(new Form({
            id: props.item.id,
            bulan: null,
            target_visit: null,
            target_noo: null,
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat Data Target Salesman',
            endpoint: '/api/atsalesman/target',
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                bulan: [
                    {
                        required: true,
                        message: 'Bulan tidak boleh kosong!',
                    },
                ],
                target_visit: [
                    {
                        required: true,
                        message: 'Target visit tidak boleh kosong!',
                    },
                ],
                target_noo: [
                    {
                        required: true,
                        message: 'Target NOO tidak boleh kosong!',
                    },
                ],
            },
            errors: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const disabledDate = current => {
            return current && current < moment().startOf('month')
        }

        const handleOk = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => {});

            let request = !form.id
                ? form.post(state.endpoint)
                : form.put(`${state.endpoint}/${form.id}`)

            request.then(({ data }) => {
                message.success('Berhasil menyimpan')

                handleModalCancel()
                emit('success', data)
            })
            .catch(({ response: { data, status } }) => {
                if (data) {
                    state.errors = data.errors
                }
            })

        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()
            } else {
                form.bulan = props.item.bulan
                form.target_visit = props.item.target_visit
                form.target_noo = props.item.target_noo

                form.readOnly = props.item.readOnly
            }

            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} Master Data SFC`
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            disabledDate,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
